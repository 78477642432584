<script>
  import FileLoader from './components/FileLoader.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';

  import {user} from 'web-stores';
  export let refreshDocumentList = () => {};
  let formData = {};
  let loading = false;
  let responded = false;
  let success = false;
  const handleFileLoad = file => {
    formData = new FormData();
    formData.append(file.name, file);
  };

  const handleDocumentSave = () => {
    loading = true;
    fetch('https://staging-smartcities-api.cox2m.com/support/documents', {
      method: 'POST',
      body: formData,
      headers: {Authorization: $user.token}
    })
      .then(response => {
        if (response.ok) {
          loading = false;
          responded = true;
          success = true;
          refreshDocumentList();
        } else {
          loading = false;
          responded = true;
          success = false;
        }
      })
      .catch(() => {
        loading = false;
        responded = true;
        success = false;
      });
  };
</script>

<style>
  .container {
    height: 200px;
  }

  header {
    height: var(--cox2m-spacing-15-units);
    display: flex;
    align-items: center;
    background-color: var(--cox2m-clr-brand-500);
  }

  h3 {
    color: var(--cox2m-clr-neutral-white);
  }

  button {
    height: var(--cox2m-spacing-13-units);
    min-width: var(--cox2m-spacing-21-units);
  }
</style>

<div
  id="document-upload-container"
  class="document-upload-container container d-flex flex-column
  justify-content-between p-0">
  <header class="px-3">
    <h3 class="m-0">Upload a Document</h3>
  </header>
  {#if !responded}
    <FileLoader {handleFileLoad} />
    <div class="d-flex justify-content-end px-3 pb-3">
      {#if !loading}
        <button
          id="save-document-button"
          class="btn btn-primary w-25 m-0"
          on:click={handleDocumentSave}>
          Save file
        </button>
      {:else}
        <LoadingSpinner />
      {/if}
    </div>
  {:else}
    <div class="h-100 d-flex justify-content-center align-items-center">
      <h2>File {success ? 'successfully' : "couldn't be"} saved.</h2>
    </div>
  {/if}
</div>

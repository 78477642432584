<script>
  import ToastMessages from "./components/notifications/ToastMessages.svelte";
  import MainDashboard from "./components/MainDashboard.svelte";
  import Header from "./components/Header.svelte";
  import Icon from "../components/Icon.svelte";
  import Menu from "./components/Menu.svelte";
  import axios from "axios";

  import { getCookieByName } from "../funcs.js";
  import { location } from "svelte-spa-router";

  export let menuOpt = undefined;
  export let title = "";
  export let user;

  let notifications = [];
  let toastNotifications = [];

  let currentOrganizationTime;

  let expandedMenu =
    screen.width < 767
      ? false
      : localStorage.getItem("isMenuExpanded") === "true";

  const TOKEN = getCookieByName(`${"staging"}_token`);

  const headers = {
    headers: {
      Authorization: TOKEN,
    },
  };

  if (!TOKEN) {
    if (document.location.hostname !== "localhost") {
      window.location.href = "https://staging-smartcities.cox2m.com/#/logout";
    }
  }

  if (TOKEN) {
    axios
      .get(
        "https://staging-smartcities-api.cox2m.com/notifications?limit=5&status=created",
        headers
      )
      .then(({ data }) => {
        notifications = Array.isArray(data.notifications)
          ? data.notifications
          : [];
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const emptyMessageFromActionsAndSuccessMessages = () => {
    toastNotifications = [];
  };

  const addToastNotification = (e) => {
    toastNotifications = [...toastNotifications, e.detail];
  };

  window.addEventListener("networkErrorEvent", addToastNotification);
  window.addEventListener("successEvent", addToastNotification);
  window.addEventListener("infoEvent", addToastNotification);

  $: localStorage.setItem("isMenuExpanded", expandedMenu);
  $: emptyMessageFromActionsAndSuccessMessages(location);
  $: currentOrganizationTime;
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>

<main class="container-fluid w-100 h-100 p-0">
  <Header
    on:menuExpanderClicked={() => (expandedMenu = !expandedMenu)}
    bind:notifications
    {user}
  >
    <slot name="main-header" />
  </Header>
  <div class="page-container">
    <div class="menu-wrapper position-relative overflow-visible">
      <div class="menu-container h-100">
        <Menu
          bind:menuOpt
          bind:expanded={expandedMenu}
        />
      </div>
      <div
        id="menu-expander-element"
        data-testid="menu-expander-element"
        class="position-absolute contained-menu-expander cursor-pointer rounded-circle"
        on:pointerdown={() => (expandedMenu = !expandedMenu)}
      >
        <Icon
          color="var(--cox2m-clr-brand-600)"
          size="var(--cox2m-spacing-6-units)"
          icon={expandedMenu ? "chevron-left" : "chevron-right"}
        />
      </div>
    </div>
    <div
      id="page-content-container"
      data-testid="page-content-container"
      class="page-content-container overflow-auto"
    >
      <MainDashboard>
        <slot name="main-dashboard" />
        <ToastMessages {toastNotifications} />
      </MainDashboard>
    </div>
  </div>
</main>

<style>
  :global(body) {
    align-items: unset;
    padding: 0px;
    min-width: 20rem;
    --header-height: var(--cox2m-spacing-14-units);
  }

  main {
    overflow: hidden;
  }

  .page-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    height: calc(100% - var(--header-height));
  }
  .menu-container {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--cox2m-clr-neutral-white);
    max-height: calc(100vh - var(--header-height));
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  }
  .menu-container::-webkit-scrollbar{
    display: none;
  }
  .menu-container:hover::-webkit-scrollbar{
    display: initial;
  }
  .page-content-container {
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .contained-menu-expander {
    transform: translateY(-50%);
    place-content: center;
    display: grid;
    right: calc(var(--cox2m-spacing-5-units) * (-1));
    top: 50%;
    width: var(--cox2m-spacing-10-units);
    height: var(--cox2m-spacing-10-units);
    background: var(--cox2m-clr-neutral-white);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  }
  .overflow-visible {
    overflow: visible;
  }
  @media only screen and (max-width: 767px) {
    .contained-menu-expander {
      display: none;
    }
    .menu-container::-webkit-scrollbar{
      display: none !important;
    }
  }
</style>

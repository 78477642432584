<script>
  import axios from "axios";
  import Icon from "../../../components/Icon.svelte";
  import { timeSince } from "../../../funcs";
  import { getCookieByName } from "../../../funcs";

  export let notification = {};
  export let handleNotificationsUpdate = () => {};

  const { id, severity, summary, message, createdAt, action } = notification;

  async function handleNotificationItemClick() {
    await handleNotificationStatusUpdate("read");
    window.open(action.replace("id", id));
  }

  async function handleNotificationStatusUpdate(status) {
    const TOKEN = getCookieByName(`${'staging'}_token`);

    axios
      .put(
        "https://staging-smartcities-api.cox2m.com/notifications/" + id,
        {
          status: status,
          token: TOKEN
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            authorization: TOKEN
          }
        }
      )
      .then(() => {
        notification = { ...notification, status: status };
        handleNotificationsUpdate(notification);
      })
      .catch(err => {
        console.error(err);
      });
  }
</script>

<style>
  .notification-item {
    padding: 0.6em;
    border-bottom: solid var(--cox2m-brd-w-1) var(--cox2m-clr-neutral-400);
    cursor: pointer;
  }

  .urgent-notification {
    border-left: var(--cox2m-spacing-1-units) solid var(--cox2m-clr-critical-500);
  }

  .notification-item-title {
    display: block;
    font-weight: bold;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin: var(--cox2m-spacing-1-units) 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-700);
  }

  .urgent-icon-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .urgent-icon-container p {
    color: var(--cox2m-clr-critical-500);
    font-weight: bold;
  }

  .notification-row {
    width: 85%;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
  .fs-1200{
    font-size: var(--cox2m-fs-1200);
  }
</style>

<div
  class={`notification-item d-flex ${severity === 'warning' ? 'urgent-notification' : ''}`}>
  <div class="notification-row" on:click={handleNotificationItemClick}>
    <span class="notification-item-title fs-1000">{summary}</span>
    <p class="fs-1200">{message}</p>
    <p class="fs-1200">{timeSince(new Date(createdAt))} ago</p>
  </div>
  {#if severity === 'warning'}
    <div
      on:click={() => handleNotificationStatusUpdate('dismissed')}
      class="urgent-icon-container">
      <Icon icon="alert-triangle" size="var(--cox2m-spacing-9-units)" color="red" />
      <p class="fs-1200">Dismiss</p>
    </div>
  {/if}
</div>
